import {Badge} from "reactstrap";
import React from "react";
import * as Yup from "yup";

export const table = {
    paginatorConfig: {
        perPage: 20,
        pages: 1,
        index: 1
    },
    pageSizeOptions: [20, 50, 75, 100]
};

export const suggestionTables = {
    paginatorConfig: {
        perPage: 20,
        pages: 1,
        index: 1
    },
    pageSizeOptions: [20, 50, 75, 100]
};

export const gameStatuses = {
    'Open': <Badge className="mr-1" color="success" pill>Open</Badge>,
    'Completed': <Badge className="mr-1" color="primary" pill>Completed</Badge>,
    'No joiners': <Badge className="mr-1" color="warning" pill>No joiners</Badge>,
    'Cancelled': <Badge className="mr-1" color="warning" pill>Cancelled</Badge>,
    'Deleted': <Badge className="mr-1" color="danger" pill>Deleted</Badge>,
};

export const payoutBillStatuses = {
    'processing': <Badge className="mr-1" color="warning" pill>Processing</Badge>,
    'completed': <Badge className="mr-1" color="success" pill>Completed</Badge>,
    'refunded': <Badge className="mr-1" color="danger" pill>Refunded</Badge>,
};

export const gamePlayersStatuses = {
    'Active': <Badge style={{position: 'absolute', right: '7px', padding: '4px 11px 6px'}} className="mr-1"
                     color="success" pill>Active</Badge>,
    'Left': <Badge style={{position: 'absolute', right: '7px', padding: '4px 11px 6px'}} className="mr-1"
                   color="warning" pill>Left</Badge>,
    'Kicked': <Badge style={{position: 'absolute', right: '7px', padding: '4px 11px 6px'}} className="mr-1"
                     color="danger" pill>Kicked</Badge>,
};
export const gamePlayersStatusesInList = {
  'Active': <Badge s
                   color="success" pill>Active</Badge>,
  'Left': <Badge
                 color="warning" pill>Left</Badge>,
  'Kicked': <Badge
                   color="danger" pill>Kicked</Badge>,
};
export const paymentStatusLabels = {
    'pending_quotation': <Badge
        style={{ position: 'absolute', left: 'calc(50% - 57px)', bottom: '-25px', padding: '4px 11px 6px' }}
        className="mr-1" color="warning" pill>Pending quotation</Badge>,
    'pending_payment': <Badge
        style={{ position: 'absolute', left: 'calc(50% - 57px)', bottom: '-25px', padding: '4px 11px 6px' }}
        className="mr-1" color="secondary" pill>Pending payment</Badge>,
    'paid': <Badge style={{ position: 'absolute', left: 'calc(50% - 21px)', bottom: '-25px', padding: '4px 11px 6px' }}
        className="mr-1" color="success" pill>Paid</Badge>,
    'unpaid': <Badge style={{ position: 'absolute', left: 'calc(50% - 25px)', bottom: '-25px', padding: '4px 11px 6px' }}
        className="mr-1" color="danger" pill>Unpaid</Badge>,
    'refunded': <Badge
        style={{ position: 'absolute', left: 'calc(50% - 35px)', bottom: '-25px', padding: '4px 11px 6px' }}
        className="mr-1" color="danger" pill>Refunded</Badge>,
    'pending_price_selection': <Badge
        style={{ position: 'absolute', left: 'calc(50% - 57px)', bottom: '-25px', padding: '4px 11px 6px' }}
        className="mr-1" color="info" pill>pending price selection</Badge>
};

export const paymentStatusLabelsInList = {
  'pending_quotation': <Badge
    className="mr-1" color="warning" pill>Pending quotation</Badge>,
  'pending_payment': <Badge
    className="mr-1" color="secondary" pill>Pending payment</Badge>,
  'paid': <Badge
                 className="mr-1" color="success" pill>Paid</Badge>,
  'unpaid': <Badge
                   className="mr-1" color="danger" pill>Unpaid</Badge>,
  'refunded': <Badge
    className="mr-1" color="danger" pill>Refunded</Badge>,
  'pending_price_selection': <Badge
    className="mr-1" color="info" pill>pending price selection</Badge>
};

export const paymentStatus = {
    pending_quotation: 'pending_quotation',
    pending_payment: 'pending_payment',
    paid: 'paid',
    unpaid: 'unpaid',
    refunded: 'refunded',
};

export const manageGamesSchema = Yup.object().shape({
    searchKey: Yup.string()
        .min(2),
});

Yup.addMethod(Yup.array, "unique", function(message) {
  return this.test("unique", message, function(list) {
    const mapper = x => x.price_key;
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `[${idx}].price_key`,
      message: message,
    });
  });
});

export const createGameSchema = Yup.object().shape({
    title: Yup.string()
        .required()
        .min(5),
    game_type_id: Yup.number()
        .required(),
    // no_of_holes: Yup.number().required(),
    no_of_total_players: Yup.number().required(),
    no_of_guests: Yup.number(),
    game_admin_id: Yup.array().required().min(1),
    game_players_ids: Yup.array(),
    start_date: Yup.string().required(),
    start_time: Yup.string().required(),
    course_id: Yup.object().required(),
    currency: Yup.string().required(),
    price: Yup.number().required(),
    venue_booked: Yup.boolean().required(),
    note: Yup.string(),
    prices_pairs: Yup.array().of(
        Yup.object().shape({
            price_key: Yup.string().required(),
            price_value: Yup.number().required()
        })
    ).unique('Key must be unique')
});

export const createGameSchemaWithPriceList = Yup.object().shape({
  title: Yup.string()
      .required()
      .min(5),
  game_type_id: Yup.number()
      .required(),
  // no_of_holes: Yup.number().required(),
  no_of_total_players: Yup.number().required(),
  no_of_guests: Yup.number(),
  game_admin_id: Yup.array().required().min(1),
  game_players_ids: Yup.array(),
  start_date: Yup.string().required(),
  start_time: Yup.string().required(),
  course_id: Yup.object().required(),
  currency: Yup.string().required(),
  price: Yup.number().required(),
  venue_booked: Yup.boolean().required(),
  note: Yup.string(),
  prices_pairs: Yup.array().required("Required one Price at least").min(1).of(
      Yup.object().shape({
          price_key: Yup.string().required(),
          price_value: Yup.number().required()
      })
  ).unique('Key must be unique')
});

export const suggestionsNotificationsSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().required(),
});

export const csvHeaders = [
    {label: '#', key: 'id'},
    {label: "First Name", key: "first_name"},
    {label: "Last Name", key: "last_name"},
    {label: "Email", key: "email"},
    {label: "Phone Number", key: "phone_number"}
];

export const playersSortingFactorsKey = {
    status: 'status',
    paymentStatus: 'paymentStatus',
};

export const playersSortingFactor = [
    {label: 'Player status', key: playersSortingFactorsKey.status},
    {label: 'Payment status', key: playersSortingFactorsKey.paymentStatus},
    {label: 'Default', key: ''},

];
